/**
 * AppContent.jsx
 *
 * This component serves as the main content wrapper for the application.
 * It sets up routing, theme provider, and renders the primary UI components.
 */

import React, { Suspense, lazy } from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import UI from "./components/UI/UI";
import Home from "./pages/Home";
import About from "./components/About/About";
import Projects from "./components/Project/Projects";
import Resume from "./components/Resume/Resume";
import Contact from "./components/Contact/Contact";
import CaseStudies from "./pages/CaseStudies";
import CaseStudyDetail from "./components/CaseStudy/CaseStudyDetail";
import GameDev from "./pages/GameDev";
import useStore from "./stores/store";

/**
 * AppContent component
 *
 * This component sets up the main structure of the application, including
 * routing and theme provisioning.
 */

// Lazy load the HexLevel experience
const HexLevel = lazy(() => import("./experiences/HexWorld/HexCraft"));

function AppContent() {
  const location = useLocation();
  const { theme } = useStore();

  return (
    <StyledThemeProvider theme={theme}>
      <div className="app-container">
        <UI>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />}>
              <Route index element={<About tab="bio" />} />
              <Route path=":tab" element={<About />} />
            </Route>
            <Route path="/projects" element={<Projects />}>
              <Route index element={<Projects />} />
              <Route path=":category" element={<Projects />} />
            </Route>
            <Route
              path="/experience/:experienceId?/:tab?"
              element={<Resume />}
            />
            <Route path="/contact" element={<Contact />} />
            <Route path="/case-studies" element={<CaseStudies />} />
            <Route
              path="/case-studies/:caseStudySlug"
              element={<CaseStudyDetail />}
            />
            <Route path="/game-dev" element={<GameDev />} />
          </Routes>
        </UI>
        <Suspense fallback={<div>Loading...</div>}>
          <HexLevel />
        </Suspense>
      </div>
    </StyledThemeProvider>
  );
}

export default AppContent;

/**
 * Usage:
 *
 * This component is the main content wrapper in the app's root component.
 * It should be rendered inside a Router component.
 *
 * Example:
 *
 * import { BrowserRouter as Router } from 'react-router-dom';
 * import AppContent from './AppContent';
 *
 * function App() {
 *   return (
 *     <Router>
 *       <AppContent />
 *     </Router>
 *   );
 * }
 */
